import { LWLModel } from "./base.js";
import { useToast } from "vue-toastification";
import auth from "@/services/auth.service";
import config from "@/app.config";
import {
  SimpleModuleGenerator,
  fetchAllWithPaging,
  fetchByIds,
} from "@/components/base/ModuleGenerator";
import store from "@/store/index.js";
import { AddressPoint } from "@/models/cadastral";
import { File } from "@/models/datapool";
import DistributorNodeSVG from "@/components/map/markers/materials/SVG/DistributorNodeSVG";
import NoteSVG from "@/components/map/markers/materials/SVG/NoteSVG";
import CenterNodeSVG from "@/components/map/markers/materials/SVG/CenterNodeSVG";
import VNodeSVG from "@/components/map/markers/materials/SVG/VNodeSVG";
import BorderNodeSVG from "@/components/map/markers/materials/SVG/BorderNodeSVG";
import HouseBoxSVG from "@/components/map/markers/materials/SVG/HouseBoxSVG";
import WellNodeSVG from "@/components/map/markers/materials/SVG/WellUFSNodeSVG.vue";
import WellZSNodeSVG from "@/components/map/markers/materials/SVG/WellZSNodeSVG.vue";
import WellUFSNodeSVG from "@/components/map/markers/materials/SVG/WellUFSNodeSVG.vue";
import TechnicalTransferPointNodeSVG from "@/components/map/markers/materials/SVG/TechnicalTransferPointNodeSVG.vue";
import { updateStateFromAPI } from "@/store/index";

export class NetworkNode extends LWLModel {
  static ENDPOINT = "node";
  static VERBOSE_NAME = "Netzwerk-Knoten";
  static VERBOSE_NAME_PLURAL = "Netzwerk-Knoten";

  static get TYPES() {
    return {
      distributor: Distributor,
      center: Center,
      well: Well,
      terminator: Terminator,
      vnode: VNode,
      bordernode: BorderNode,
      note: Note,
      interestnote: InterestNote,
      housebox: HouseBox,
      technicaltransferpoint: TechnicalTransferPoint,
    };
  }

  static ICON_MAP = {
    distributor: DistributorNodeSVG,
    center: CenterNodeSVG,
    well: WellNodeSVG,
    ufs: WellUFSNodeSVG,
    zs: WellZSNodeSVG,
    note: NoteSVG,
    interestnote: NoteSVG,
    bordernode: BorderNodeSVG,
    housebox: HouseBoxSVG,
    vnode: VNodeSVG,
    technicaltransferpoint: TechnicalTransferPointNodeSVG,
  };

  static getIconByType(type) {
    return this.ICON_MAP[type] || null;
  }

  static from_api(data, parent, model) {
    // if (!this.TYPES[data.type]) console.error("Unknown node type", data.type, data);
    return LWLModel.from_api(data, parent, this.TYPES[data.type] || this);
  }

  static fromEmpty(title) {
    const data = {
      type: this.ENDPOINT.toLowerCase(),
      id: -1,
      title: title || "Lade ...",
      geom: {
        type: "Point",
        coordinates: [0, 0],
      },
      schema: null,
    };
    return this.fromAPI(data);
  }

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.PROJECTS, param);
  }

  static async fetchByIds(projectId, ids) {
    return fetchByIds(this, config.endpoints.PROJECTS, projectId, ids);
  }

  getOwner() {
    return this.owner ? store.getters["accounts/usersById"](this.owner) : null;
  }

  getSpliceTrays() {
    store.dispatch(
      "network/fetchAllSpliceTrays",
      store.getters["projects/project"].id
    );
    return store.getters["network/spliceTraysByIds"](
      this.splicetrays.map((ref) => ref.id)
    ).filter((tray) => !tray.splice_closure);
  }

  getSpliceClosures() {
    store.dispatch(
      "network/fetchAllSpliceClosures",
      store.getters["projects/project"].id
    );
    return store.getters["network/spliceClosuresByIds"](
      this.splice_closures.map((ref) => ref.id)
    );
  }

  getCabinets() {
    store.dispatch(
      "network/fetchAllCabinets",
      store.getters["projects/project"].id
    );
    return store.getters["network/cabinetsByIds"](
      this.cabinets.map((ref) => ref.id)
    );
  }

  getBunchConnections() {
    return store.getters["network/bunchConnectionByIds"](
      this.bunch_connections.map((n) => n.id)
    );
  }

  getPipes(nested = true) {
    store.dispatch(
      "network/fetchAllPipes",
      store.getters["projects/project"].id
    );
    const pipes = store.getters["network/pipesByIds"](
      this.pipes.map((ref) => ref.id)
    );

    if (!nested) return pipes.filter((p) => !p.in_tube); //no nested pipes
    else return pipes;
  }

  getAllRoutes() {
    let routes = [];
    this.getPipes(false).forEach((p) => {
      routes = routes.concat(p.getAllRoutes());
    });
    routes = routes.filter((r) => r.getNodes().includes(this));
    return routes;
  }

  getNestedPipes() {
    store.dispatch(
      "network/fetchAllPipes",
      store.getters["projects/project"].id
    );
    const pipes = store.getters["network/pipesByIds"](
      this.pipes.map((ref) => ref.id)
    );
    return pipes.filter((p) => p.in_tube); //only nested pipes
  }

  getCableSections() {
    store.dispatch(
      "network/fetchAllCables",
      store.getters["projects/project"].id
    );
    return store.getters["network/cableSectionByIds"](
      this.cable_sections.map((ref) => ref.id)
    );
  }

  getAssignedHouseBoxes() {
    return store.getters["network/nodesByIds"](
      this.assigned_houseboxes.map((ref) => ref.id)
    );
  }

  getSchemaCoordinates() {
    return this.schema ? this.schema.coordinates : this.geom.coordinates;
  }

  getFibers() {
    store.dispatch("network/fetchNodeFibers", this);
    return store.getters["network/nodeFibersById"](this.id);
  }

  getTubeConnectors() {
    return this.tube_connectors.map((tc) => {
      return TubeConnector.from_api(tc);
    });
  }

  isOfMainType() {
    return [
      "distributor",
      "well",
      "center",
      "vnode",
      "bordernode",
      "technicaltransferpoint",
    ].includes(this.type);
  }
  isVNode() {
    return this.type === "vnode";
  }

  hasType() {
    return ["well", "distributor", "center", "technicaltransferpoint"].includes(
      this?.type
    );
  }
}

export class NetworkNodeFibers extends LWLModel {
  static async fetchFibers(url) {
    return auth.get(`${config.BACKEND_PORTAL}${url}`).then((response) => {
      return NetworkNodeFibers.from_api(response.data);
    });
  }

  getFibers() {
    return this.fibers
      .map((f) => {
        return Fiber.from_api(f);
      })
      .sort((a, b) => {
        return b.network_route.length - a.network_route.length;
      });
  }
}

export class VNode extends NetworkNode {
  static ENDPOINT = "vnode";
  static CLASS_NAME = "VNode";
  static VERBOSE_NAME = "Virtueller Endpunkt";
  static VERBOSE_NAME_PLURAL = "Virtuelle Endpunkte";
  static INITIALS = "VE";

  static from_api(data) {
    let instance = new this();
    for (const [key, value] of Object.entries(data)) {
      instance[key] = value;
    }
    return instance;
  }

  prepareForApi() {
    let formData = new FormData();
    formData.append("title", this.title);
    let coordinates = this.schema.coordinates;
    formData.append("schema", `POINT(${coordinates[0]} ${coordinates[1]})`);
    formData.append("geom", `POINT(${coordinates[0]} ${coordinates[1]})`);
    formData.append("status_id", this.status);
    return formData;
  }

  create() {
    return auth
      .post(
        `${config.endpoints.PROJECTS}${this.project.id}/vnode/`,
        this.prepareForApi()
      )
      .then((response) => {
        return VNode.from_api(response.data);
      });
  }

  isManuallyCreated() {
    return this.source.label === "manuell";
  }

  getCreateColor() {
    return this.isManuallyCreated() ? "#E9EFF4" : "black";
  }
}
export class BorderNode extends VNode {
  static ENDPOINT = "bordernode";
  static CLASS_NAME = "BorderNode";
  static VERBOSE_NAME = "Grenzpunkt";
  static VERBOSE_NAME_PLURAL = "Grenzpunkte";
  static INITIALS = "GP";

  create() {
    return auth
      .post(
        `${config.endpoints.PROJECTS}${this.project.id}/bordernode/`,
        this.prepareForApi()
      )
      .then((response) => {
        return BorderNode.from_api(response.data);
      });
  }
}
export class Note extends NetworkNode {
  static DISPLAY_DEFAULT = "__all__";
  static DISPLAY_MAP = "map";
  static DISPLAY_SCHEMA = "schema";

  static ENDPOINT = "note";
  static VERBOSE_NAME = "Notiz";
  static VERBOSE_NAME_PLURAL = "Notizen";

  static from_api(data) {
    let instance = new this();
    for (const [key, value] of Object.entries(data)) {
      instance[key] = value;
    }
    return instance;
  }
  static getMapLayerOptions() {
    return [
      {
        value: Note.DISPLAY_MAP,
        label: "Karte",
      },
      {
        value: Note.DISPLAY_SCHEMA,
        label: "Schema",
      },
      // {
      //   value: Note.DISPLAY_DEFAULT,
      //   label: "Karte & Schema",
      // },
    ];
  }

  prepareForApi() {
    let formData = new FormData();
    let coordinates = this.schema.coordinates;
    formData.append("title", this.title);
    if (this.description) formData.append("description", this.description);
    formData.append("schema", `POINT(${coordinates[0]} ${coordinates[1]})`);
    formData.append("geom", `POINT(${coordinates[0]} ${coordinates[1]})`);
    formData.append("display_on_map", this.mapLayer);
    return formData;
  }

  create() {
    return auth
      .post(
        `${config.endpoints.PROJECTS}${this.project.id}/note/`,
        this.prepareForApi()
      )
      .then((response) => {
        return Note.from_api(response.data);
      });
  }
}

export class InterestNote extends Note {
  static ENDPOINT = "interestnote";
  static VERBOSE_NAME = "Interessens-Notiz";
  static VERBOSE_NAME_PLURAL = "Interessens-Notizen";

  prepareForApi() {
    let formData = new FormData();
    let coordinates = this.schema.coordinates;
    formData.append("title", this.title);
    if (this.description) formData.append("description", this.description);
    if (this.provider) formData.append("provider", this.provider);
    formData.append("schema", `POINT(${coordinates[0]} ${coordinates[1]})`);
    formData.append("geom", `POINT(${coordinates[0]} ${coordinates[1]})`);
    formData.append("display_on_map", this.mapLayer);
    return formData;
  }

  create() {
    return auth
      .post(
        `${config.endpoints.PROJECTS}${this.project.id}/${InterestNote.ENDPOINT}/`,
        this.prepareForApi()
      )
      .then((response) => {
        return InterestNote.from_api(response.data);
      });
  }

  getProvider() {
    return this.provider
      ? store.getters["providers/providersById"](this.provider)
      : null;
  }
}

export class Distributor extends NetworkNode {
  static ENDPOINT = "distributor";
  static VERBOSE_NAME = "Verteiler";
  static VERBOSE_NAME_PLURAL = "Verteiler";
}
export class Center extends NetworkNode {
  static ENDPOINT = "center";
  static VERBOSE_NAME = "Zentrale";
  static VERBOSE_NAME_PLURAL = "Zentralen";
}
export class Well extends NetworkNode {
  static ENDPOINT = "well";
  static VERBOSE_NAME = "Schacht";
  static VERBOSE_NAME_PLURAL = "Schächte";
}

export class TechnicalTransferPoint extends NetworkNode {
  static ENDPOINT = "technicaltransferpoint";
  static VERBOSE_NAME = "Tech. Übergabepunkt";
  static VERBOSE_NAME_PLURAL = "Tech. Übergabepunkte";
}

export class HouseBox extends NetworkNode {
  static ENDPOINT = "housebox";
  static VERBOSE_NAME = "Hausanschluss";
  static VERBOSE_NAME_PLURAL = "Hausanschlüsse";

  static CONNECTION_STATUS_PLANNING = 0;
  static CONNECTION_STATUS_INTERESTED = 10;
  static CONNECTION_STATUS_PREPARED = 20;
  static CONNECTION_STATUS_INSTALLATION = 25;
  static CONNECTION_STATUS_CONNECTED = 30;

  static CONNECTION_STATUS_PLANNING_COLOR = "#757575";
  static CONNECTION_STATUS_INTERESTED_COLOR = "#081D34";
  static CONNECTION_STATUS_PREPARED_COLOR = "#081D34";
  static CONNECTION_STATUS_INSTALLATION_COLOR = "#F57600";
  static CONNECTION_STATUS_CONNECTED_COLOR = "#217011";

  static CONNECTION_STATUS_CHOICES = [
    [
      HouseBox.CONNECTION_STATUS_PLANNING,
      "in Planung",
      HouseBox.CONNECTION_STATUS_PLANNING_COLOR,
    ],
    [
      HouseBox.CONNECTION_STATUS_INTERESTED,
      "interessiert",
      HouseBox.CONNECTION_STATUS_INTERESTED_COLOR,
    ],
    [
      HouseBox.CONNECTION_STATUS_PREPARED,
      "verlegt",
      HouseBox.CONNECTION_STATUS_PREPARED_COLOR,
    ],
    [
      HouseBox.CONNECTION_STATUS_INSTALLATION,
      "zur Montage freigegeben",
      HouseBox.CONNECTION_STATUS_INSTALLATION_COLOR,
    ],
    [
      HouseBox.CONNECTION_STATUS_CONNECTED,
      "hergestellt",
      HouseBox.CONNECTION_STATUS_CONNECTED_COLOR,
    ],
  ];

  static getConnectionStatusColorByLabel(label) {
    let connection_status = HouseBox.CONNECTION_STATUS_CHOICES.find(
      ([value, text, color]) => text === label
    );
    return connection_status ? connection_status[2] : null;
  }

  getConnectionStatusColor() {
    let connection_status = HouseBox.CONNECTION_STATUS_CHOICES.find(
      ([value, text, color]) => value === this.connection_status.id
    );
    return connection_status ? connection_status[2] : null;
  }

  getUsageUnitByFiberUid(fiberUid) {
    return fiberUid in this.usage_units ? this.usage_units[fiberUid] : null;
  }

  getAddresspoint() {
    return this.addresspoint ? AddressPoint.from_api(this.addresspoint) : null;
  }
  getAssignedNode() {
    return this.assigned_to
      ? store.getters["network/nodesById"](this.assigned_to)
      : null;
  }
  getFirstConnectedTube() {
    let pipes = this.getPipes();
    if (pipes.length) {
      let pipe = pipes[0];
      let tubes = pipe.getTubesOnNode(this);
      if (tubes.length) {
        return tubes[0];
      }
    }
  }

  isConnectedCondition = (otherNode, targetNode, tubeConnector) =>
    otherNode.id === targetNode.id;

  isInterruptedCondition = (otherNode, targetNode, tubeConnector) =>
    otherNode.id !== targetNode.id && !tubeConnector;

  getConnectedTubesWithNodesToAssignedNode(node, condition) {
    let pipes = this.getPipes();
    if (!pipes) return [];
    let connectedTubes = pipes.reduce((acc, pipe) => {
      let tubes = pipe.getTubesOnNode(this).reduce((acc, tube) => {
        let startNode = this;
        let startTube = tube;

        while (startTube) {
          let otherNode = startTube.getOtherNode(startNode);
          let tubeConnector = startTube.getConnectorAt(otherNode);
          if (condition(otherNode, node, tubeConnector)) {
            acc.push({ tube: startTube, node: otherNode });
            //acc.push(startTube);
            break;
          }

          startNode = otherNode;
          startTube = tubeConnector
            ? tubeConnector.getOtherTube(startTube)
            : null;
        }
        return acc;
      }, []);

      if (tubes.length != 0) {
        acc.push(...tubes);
      }
      return acc;
    }, []);
    return connectedTubes;
  }

  getConnectedTubes() {
    let pipes = this.getPipes();
    let tubes = pipes.reduce((acc, pipe) => {
      acc.push(...pipe.getTubesOnNode(this));
      return acc;
    }, []);
    return tubes;
  }

  get assignedFiles() {
    return this.files.map((file) => File.from_api(file));
  }

  static async fetchByIdAndUpdateState(houseBoxId) {
    let houseBox = store.getters["network/nodesHouseBoxById"](houseBoxId);
    if (houseBox) {
      store.dispatch("network/fetchHouseBoxAndUpdateState", houseBox);
    } else {
      console.warn(`Could not update state of house box with id ${houseBoxId}`);
    }
  }
}
export class Terminator extends NetworkNode {
  static ENDPOINT = "terminator";
  static VERBOSE_NAME = "Schnittpunkt";
  static VERBOSE_NAME_PLURAL = "Schnittpunkte";
}

export class NetworkComponent extends LWLModel {
  static ENDPOINT = null;
  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.PROJECTS, param);
  }
  static async fetchByIds(projectId, ids) {
    return fetchByIds(this, config.endpoints.PROJECTS, projectId, ids);
  }

  getLayoutOnNode(node) {
    return this.layout[node.id];
  }

  getNodes() {
    return [this.getNode1(), this.getNode2()];
  }

  getOtherNode(node) {
    if (!node) {
      console.error("No node given", node);
    }

    let other = this.getNodes().find((x) => x.id != node.id);
    if (other) {
      return other;
    } else {
      console.error(
        "NetworkComponent.getOtherNode: No other node found.",
        node.id,
        this
      );
    }
  }

  getOwner() {
    return this.owner ? store.getters["accounts/usersById"](this.owner) : null;
  }

  checkStartAndEndNode(node1, node2) {
    return (
      (this.getNode1().id === node1.id && this.getNode2().id === node2.id) ||
      (this.getNode1().id === node2.id && this.getNode2().id === node1.id)
    );
  }
}

export class NetworkRoute extends LWLModel {
  static from_api(data, parent, model) {
    if (Array.isArray(data)) {
      let route = new NetworkRoute();
      route.all = data;
      return route;
    } else {
      return LWLModel.from_api(data, parent, NetworkRoute);
    }
  }

  static fromTubes(tubes) {
    let route = [];
    tubes.forEach((tube) => {
      if (route.length === 0) {
        let start = tube.getNode1();
        if (tubes.length > 1) {
          let next = tubes[1];
          if (
            next.getNode1().id === start.id ||
            next.getNode2().id === start.id
          ) {
            start = tube.getNode2();
          }
        }
        route.push({
          type: "node",
          id: start.id,
          title: start.title,
        });
      }
      route.push({
        type: "tube",
        id: tube.id,
        title: tube.title,
        distance: tube.distance,
      });
      route.push({
        type: "node",
        id: tube.getOtherNode(route[route.length - 2]).id,
        title: tube.getOtherNode(route[route.length - 2]).title,
      });
    });
    return this.fromAPI(route);
  }

  hasParts() {
    return Boolean(this.before && this.self && this.after);
  }
  getAll() {
    if (this.hasParts()) {
      return this.before.concat(this.self).concat(this.after);
    } else {
      return this.all;
    }
  }
  getNodes() {
    return this.getAll().filter((n) => n.type !== "tube");
  }
  getTubes() {
    return this.getAll().filter((n) => n.type === "tube");
  }
  getConnections() {
    return this.getAll().filter((n) => n.type === "connection");
  }
  getDistance() {
    let distance = 0;
    this.getTubes().forEach((x) => {
      distance += x.distance;
    });
    this.getConnections().forEach((x) => {
      distance += x.distance;
    });
    return distance;
  }
  getSubRoute(start_id, end_id) {
    let all = this.getAll();
    let si = all.findIndex((x) => x.id === start_id);
    let ei = all.findIndex((x) => x.id === end_id);
    let sub;
    if (si > ei) sub = all.slice(ei, si + 1);
    else sub = all.slice(si, ei + 1);
    return NetworkRoute.from_api({
      before: [],
      self: sub,
      after: [],
    });
  }

  getNodeAt(index) {
    index = index < 0 ? this.getNodes().length + index : index;
    return store.getters["network/nodesById"](this.getNodes()[index].id);
  }

  getFirstNode() {
    return this.getNodeAt(0);
  }
  getLastNode() {
    return this.getNodeAt(-1);
  }
  getHouseBox() {
    let hb = this.all.find((el) => el.type === "housebox");
    if (hb) {
      return store.getters["network/nodesById"](hb.id);
    }
    return null;
  }
  hasHouseBox() {
    return this.all.some((el) => el.type === "housebox");
  }
  getLastBunchConnectionId() {
    let route = [...this.all].reverse();
    let bc = route.find((el) => el.type === "bunch-connection");
    return bc ? bc.id : null;
  }
  getLastFiberColorCode() {
    let route = [...this.all].reverse();
    let conn = route.find((el) => el.type === "connection");
    return conn ? conn.color : null;
  }
}

export class Pipe extends NetworkComponent {
  static ENDPOINT = "pipe";
  static VERBOSE_NAME = "Rohrverbindung";
  static VERBOSE_NAME_PLURAL = "Rohrverbindungen";

  static async fetchAllByCoordinates(lng, lat) {
    const base_url = `${config.endpoints.PROJECTS}${store.getters["projects/project"].id}/${this.ENDPOINT}/by-coordinates/`;
    const params = `?lng=${lng}&lat=${lat}`;
    const url = base_url + params;

    return auth
      .get(url)
      .then((response) => {
        return response.data.result.map((p) => Pipe.from_api(p));
      })
      .catch((error) => {
        let status = error.toJSON().status;
        if (status === 403) {
          console.info("Missing Permissions: " + url);
        } else {
          throw error;
        }
      });
  }

  static fromEmpty(title) {
    const data = {
      type: { composite: false },
      id: -1,
      title: title || "Lade ...",
    };
    return this.fromAPI(data);
  }

  get loaded() {
    return this.id > -1 && this.getNode1().loaded && this.getNode2().loaded;
  }

  getTitle() {
    return this.type.title;
  }

  getLayoutOnNode(node) {
    let layout = {
      direction: this.getSchemaDirectionTo(node),
      order: 0,
    };

    //merge with a stored layout
    let stored = this.layout[node.id];
    if (stored) {
      for (const [key, value] of Object.entries(stored)) {
        if (value !== null) layout[key] = value;
      }
    }

    return layout;
  }

  getTubes(force) {
    store.dispatch(
      "network/fetchAllTubes",
      store.getters["projects/project"].id
    );
    let ids = this.tubes.map((x) => x.id);
    let resolved = store.getters["network/tubesByIds"](ids);
    return resolved.sort((a, b) => a.sort - b.sort); //force order by sort
  }

  getTubesOnNode(node) {
    return this.getTubes().filter(
      (x) => x.getNode1() === node || x.getNode2() === node
    );
  }

  getFirstCableSection() {
    try {
      return this.getTubes()[0].getCableSections()[0];
    } catch (e) {
      return null;
    }
  }

  getFirstCable() {
    try {
      return this.getFirstCableSection().getCable();
    } catch (e) {
      return null;
    }
  }

  getAllCableSections() {
    let sections = [];
    this.getTubes().forEach((tube) => {
      sections = sections.concat(tube.getCableSections());
    });
    return sections;
  }

  get composite() {
    return this.type.composite;
  }

  getSchemaPositionId() {
    let coords = this.getNode1()
      .getSchemaCoordinates()
      .concat(this.getNode2().getSchemaCoordinates());
    return coords.sort().join("-");
  }

  getNode1() {
    return store.getters["network/nodesById"](this.node1.id);
  }

  getNode2() {
    return store.getters["network/nodesById"](this.node2.id);
  }

  getSchemaDirectionTo(node) {
    let to = node.getSchemaCoordinates();
    let from;

    // console.log("getSchemaDirectionTo", this, node, to)
    try {
      from = this.getOtherNode(node).getSchemaCoordinates();
    } catch (e) {
      console.error(e);
      return "left";
    }
    if (from[0] == to[0]) {
      return from[1] < to[1] ? "right" : "left";
    } else {
      return from[0] < to[0] ? "left" : "right";
    }
  }

  getNetworkRoute() {
    return NetworkRoute.from_api(this.network_route);
  }

  getAllRoutes() {
    store.dispatch(
      "routes/fetchAllConnectedRoutes",
      store.getters["projects/project"].id
    );
    let routes = this.getTubes().map((tube) => {
      return tube.route ? tube.route.id : -1;
    });
    routes = [...new Set(routes)]; // unique ids
    return store.getters["routes/connectedRoutesByIds"](routes);
  }

  isConnectingMainTypes() {
    return this.getNodes().every((x) => x.isOfMainType());
  }

  isNestedPipe() {
    return this.in_tube !== null;
  }

  hasNonConnectedTubes(node) {
    let connectors = this.tubeConnectorsByNode(node);
    // no possible connection because all tubes are already connected
    if (connectors.length === this.getTubes().length) return false;
    return true;
  }

  hasConnectedTubes(node) {
    let connectors = this.tubeConnectorsByNode(node);
    return connectors.length > 0;
  }

  tubeConnectorsByNode(node) {
    return node.getTubeConnectors().filter((tc) => {
      let tubes = this.getTubes();
      return tubes.includes(tc.getTube1()) || tubes.includes(tc.getTube2());
    });
  }

  isConnectableToOtherPipe(node, pipe) {
    if (pipe.type.id !== this.type.id) return false;

    const sortConnectors = (connectors) =>
      connectors.sort((a, b) => a.id - b.id);
    let connPipe1 = sortConnectors(this.tubeConnectorsByNode(node));
    let connPipe2 = sortConnectors(pipe.tubeConnectorsByNode(node));

    // no connections to other pipes
    if (connPipe1.length === 0 && connPipe2.length === 0) return true;
    // different amount of connectors
    if (connPipe1.length !== connPipe2.length) return false;
    // check if both pipes have same connectors
    for (let i = 0; i < connPipe1.length; i++) {
      if (connPipe1[i].id !== connPipe2[i].id) {
        return false;
      }
    }
    return true;
  }

  hasTubesBetweenNodes(node1, node2) {
    return this.getTubes().some((tube) => {
      return tube.checkStartAndEndNode(node1, node2);
    });
  }
}

export class Tube extends NetworkComponent {
  static ENDPOINT = "tube";
  static VERBOSE_NAME = "Röhrchen";
  static VERBOSE_NAME_PLURAL = "Röhrchen";

  static fromEmpty(title) {
    const data = {
      id: -1,
      title: title || "Lade ...",
      color: {
        web: "#000000",
      },
      route: { id: -1, distance: 0 },
      node1: { id: -1 },
      node2: { id: -1 },
    };
    return this.fromAPI(data);
  }

  set title(val) {
    this._title = val;
  }
  get title() {
    return `${this._title} ${this.diameter}`;
  }

  getNode1() {
    return store.getters["network/nodesById"](this.node1.id);
  }
  getNode2() {
    return store.getters["network/nodesById"](this.node2.id);
  }

  getNode1EOC() {
    return {
      node: store.getters["network/nodesById"](this.node1_eoc.node),
      distance: this.node1_eoc.distance,
    };
  }
  getNode2EOC() {
    return {
      node: store.getters["network/nodesById"](this.node2_eoc.node),
      distance: this.node2_eoc.distance,
    };
  }
  getOtherEOC(node) {
    if (this.getOtherNode(node).id === this.node1.id) {
      return this.getNode1EOC();
    } else if (this.getOtherNode(node).id === this.node2.id) {
      return this.getNode2EOC();
    } else {
      // console.warn("NetworkComponent.getOtherEOS: Node not found.", this, node);
    }
  }

  getNetworkRoute() {
    return NetworkRoute.from_api(this.network_route);
  }
  getPipe() {
    return store.getters["network/pipesById"](this.pipe);
  }
  getRoute() {
    store.dispatch(
      "routes/fetchAllConnectedRoutes",
      store.getters["projects/project"].id
    );
    return store.getters["routes/connectedRoutesById"](this.route.id);
  }

  getCableSections() {
    store.dispatch(
      "network/fetchAllCables",
      store.getters["projects/project"].id
    );
    return store.getters["network/cableSectionByIds"](
      this.cable_sections.map((ref) => ref.id)
    );
  }

  getNestedPipes() {
    store.dispatch(
      "network/fetchAllPipes",
      store.getters["projects/project"].id
    );
    return store.getters["network/pipesByIds"](
      this.nested_pipes.map((ref) => ref.id)
    );
  }

  getConnectorAt(node) {
    let connectors = node.getTubeConnectors();
    connectors = connectors.filter((c) => {
      return c.tube1.id === this.id || c.tube2.id === this.id;
    });
    return connectors.length ? connectors[0] : null;
  }

  isConnectedAt(node) {
    return Boolean(this.getConnectorAt(node));
  }

  isUsedAt(node) {
    return (
      this.isConnectedAt(node) || !this.allCableSectionsAreContinuous(node)
    );
  }

  allCableSectionsAreContinuous(node) {
    return this.getCableSections().every((cs) =>
      cs.getCable().isGoingThroughNode(node)
    );
  }
}

export class Cable extends NetworkComponent {
  static ENDPOINT = "cable";
  static VERBOSE_NAME = "Kabel";
  static VERBOSE_NAME_PLURAL = "Kabel";

  static async fetchAllByCoordinates(lng, lat) {
    const base_url = `${config.endpoints.PROJECTS}${store.getters["projects/project"].id}/${this.ENDPOINT}/by-coordinates/`;
    const params = `?lng=${lng}&lat=${lat}`;
    const url = base_url + params;

    return auth
      .get(url)
      .then((response) => {
        return response.data.result.map((p) => Cable.from_api(p));
      })
      .catch((error) => {
        let status = error.toJSON().status;
        if (status === 403) {
          console.info("Missing Permissions: " + url);
        } else {
          throw error;
        }
      });
  }

  getTitle() {
    return this.title || this.type.title;
  }

  getSections() {
    let section_ids = this.sections.map((s) => s.id);
    return store.getters["network/cableSectionByIds"](section_ids);
  }

  getNetworkRoute() {
    return NetworkRoute.from_api(this.network_route);
  }

  getAllBunches() {
    return this.type.bunches.map((b) => {
      return Bunch.fromCable(this, b);
    });
  }

  getAllBunchesSortedByGroup() {
    let bunches = this.type.bunches.map((b) => {
      return Bunch.fromCable(this, b);
    });

    return bunches.reduce((acc, bunch) => {
      if (!acc[bunch.group]) {
        acc[bunch.group] = [];
      }
      acc[bunch.group].push(bunch);
      return acc;
    }, {});
  }

  getSectionsInNode(node) {
    return this.getSections().filter((s) =>
      [s.getNode1().id, s.getNode2().id].includes(node.id)
    );
  }

  isGoingThroughNode(node) {
    return this.getSectionsInNode(node).length >= 2;
  }

  getColorCode() {
    return ColorCode.fromAPI(this.color_code);
  }
}

export class ColorCode extends LWLModel {
  static IGNORED_COLOR_CODES = ["#ECECE7", "#FFFF00", "#F1DD38"];
  static DEFAULT_COLOR_CODE_DARK = "#000000";
  static DEFAULT_COLOR_CODE_LIGHT = "#FFFFFF";

  static isIgnoredColorCode(color_code) {
    return this.IGNORED_COLOR_CODES.includes(color_code);
  }
  // use this to get the color code considering the ignored colors
  static getWebColorCodeByCode(color_code) {
    if (this.isIgnoredColorCode(color_code.toUpperCase())) {
      return this.DEFAULT_COLOR_CODE_DARK;
    }
    return color_code;
  }

  getColors() {
    return this.colors.map((c) => Color.fromAPI(c, this));
  }
  getColorByTitle(title) {
    return this.getColors().find((c) => c.title === title);
  }
  getIndexByTitle(title) {
    let index = this.getColors().findIndex((c) => c.title === title);
    return index + 1;
  }
  getAllTitles() {
    return this.getColors().map((c) => c.title);
  }
}

export class Color extends LWLModel {
  asFiberOfBunchConnection(bunchConnection) {
    return Fiber.fromAPI({
      fiber: this.title,
      index: this.parent.getIndexByTitle(this.title),
      color: this,
      bunch_connection: bunchConnection,
      network_route: [],
    });
  }
}

export class CableSection extends NetworkComponent {
  static ENDPOINT = "cablesection";

  getCable() {
    return store.getters["network/cablesById"](this.cable.id);
  }
  getBunchConnections() {
    return store.getters["network/bunchConnectionByIds"](
      this.bunch_connections.map((n) => n.id)
    );
  }
  getBunchConnectionsInNode(node) {
    return this.getBunchConnections().filter((c) => c.node.id === node.id);
  }

  getTube() {
    return store.getters["network/tubesById"](this.tube.id);
  }
  getNode1() {
    return this.getTube().getNode1();
  }
  getNode2() {
    return this.getTube().getNode2();
  }

  getEndOfCable(node) {
    let route = this.getCable().getNetworkRoute();
    let routeNodes = route.getNodes();

    // get direction of the cable section (use tube to get right direction also on u-turns)
    let ni = route
      .getAll()
      .findIndex((n) => n.id === node.id && n.title === node.title);
    let oni = route
      .getAll()
      .findIndex((n) => n.type === "tube" && this.tube.id === n.id);

    if (ni < oni) {
      return routeNodes[routeNodes.length - 1];
    } else {
      return routeNodes[0];
    }
  }

  getAllBunches() {
    let bcs = this.getBunchConnections();
    return this.getCable().type.bunches.map((b) => {
      let bc = bcs.find((bc) => bc.bunch.id === b.id);
      if (bc) {
        return Bunch.fromBunchConnection(bc, b);
      } else {
        return Bunch.fromCableSection(this, b);
      }
    });
  }
  getAvailableBunchesOnNode(node) {
    let bcs = this.getBunchConnectionsInNode(node);
    let free = this.getAllBunches().filter((b) => {
      let bc = bcs.find((bc) => bc.bunch.id === b.id);
      return bc ? Boolean(bc.getFreeFibersOfBunch().length) : true;
    });
    return free;
  }

  isGoingThroughNode(node) {
    let cable = this.getCable();
    let cableSections = cable.getSectionsInNode(node);
    if (cableSections.length === 1) return false;
    if (cableSections.length % 2 === 0) return true;
    let candidates = cable.getSections().filter((cs) => {
      return cs.order === this.order - 1 || cs.order === this.order + 1;
    });
    return candidates.length === 2 ;
  }

  getUsedBunchesOnNode(node) {
    return []; // TODO: Implement
  }
}

export class CableSectionBunchRoute extends LWLModel {
  static async fetchRoute(route_url) {
    return auth
      .get(`${config.endpoints.PROJECTS}${route_url}`)
      .then((response) => {
        return CableSectionBunchRoute.from_api(response.data);
      });
  }

  getNetworkRouteByBunch(bunch) {
    bunch = this.bunches.find(
      (b) =>
        b.bunch_type.title === bunch.title && b.bunch_type.group === bunch.group
    );
    if (!bunch) return null;
    return NetworkRoute.from_api(bunch.network_route);
  }
}

export class Bunch extends LWLModel {
  static fromCable(cable, data) {
    let bunch = Bunch.from_api(data);
    bunch.cable = cable;
    return bunch;
  }

  static fromCableSection(cs, data) {
    let bunch = Bunch.from_api(data);
    bunch.cableSection = cs;
    bunch.cable = cs.getCable();
    return bunch;
  }

  static fromBunchConnection(bc, data) {
    let bunch = Bunch.from_api(data);
    bunch.bunchConnection = bc;
    bunch.cableSection = bc.getCableSection();
    bunch.cable = bc.getCableSection().getCable();
    return bunch;
  }

  getCable() {
    return this.cable;
  }

  getCableSection() {
    return this.cableSection;
  }

  get uid() {
    if (this.getCableSection()) {
      return "bunch-" + this.getCableSection().id + "-" + this.id;
    } else {
      return "bunch-" + this.id;
    }
  }

  getAllFibers() {
    return this.getCable()
      .getColorCode()
      .getColors()
      .slice(0, this.fibers)
      .map((c) => c.asFiberOfBunchConnection(this.bunchConnection));
  }

  getFreeFibersOnNode(node) {
    // get all bunch connections of the bunch on this node
    let bunchConnections = node
      .getBunchConnections()
      .filter((bc) => bc.getBunch().uid === this.uid);

    if (!bunchConnections.length) {
      // return all fibers of the bunch if there is no bunch connection on this node
      return this.getAllFibers();
    }

    // get all used fiber colors of the bunch on this node
    let usedFiberColors = bunchConnections.reduce((acc, bc) => {
      return acc.concat(Object.values(bc.fibers));
    }, []);

    // return all free fibers of the bunch on this node
    return this.getAllFibers().filter((f) => {
      return !usedFiberColors.includes(f.fiber);
    });
  }

  getDescription() {
    return `${this.group} / ${this.color.title}`;
  }

  hasGroup() {
    return this.group !== "-";
  }
}

export class BunchConnector extends NetworkComponent {
  getBunchConnections() {
    let resolved = store.getters["network/bunchConnectionByIds"](
      this.bunch_connections.map((n) => n.id)
    );
    return resolved;
  }

  getNode() {
    return store.getters["network/nodesById"](this.node.id);
  }
}
export class BunchConnection extends NetworkComponent {
  static ENDPOINT = "bunchconnection";

  get uid() {
    return `bunchconnection-${this.id || this.pk}`;
  }

  getTitleParts() {
    const cs = this.getCableSection();
    const cable = cs.getCable();
    const other = this.getOtherEnd();

    let parts = [];
    if (cable.title) parts.push(cable.title);
    parts = [].concat(parts, ["➔", other.title]);
    if (this.bunch.group) parts = [].concat(parts, [this.bunch.group, "/"]);
    parts.push(this.bunch.title);
    return parts;
  }
  getOtherEnd() {
    const node = this.getConnector().getNode();
    const cs = this.getCableSection();
    const cable = cs.getCable();

    // COMMENT bunch routing should be a better solution for this!!
    let cs_sorted = cable.getSections().sort((a, b) => a.order - b.order);
    let cs_index = cs_sorted.findIndex((e) => e.id === cs.id);

    let isStartOfCable = cs_index === 0 || cs_index === cs_sorted.length - 1;

    // if cable section has housebox and cable section is the first one return end of cable
    if (isStartOfCable && cable.getNetworkRoute().hasHouseBox()) {
      return cs.getEndOfCable(node);
    }
    // else next node of cable section
    return cs.getOtherNode(node);
  }

  getDescriptiveTitle() {
    let parts = this.getTitleParts();
    return parts.join(" ");
  }

  getTitle() {
    let parts = [];
    if (this.bunch.group !== "-")
      parts = [].concat(parts, [this.bunch.group, "/"]);
    parts.push(this.bunch.title);
    return parts.join(" ");
  }

  getOtherConnectionsOfBunch() {
    return this.getCableSection()
      .getBunchConnections()
      .filter((bc) => {
        return bc.id !== this.id && bc.getBunch().id === this.getBunch().id;
      });
  }

  getFreeFibersOfBunch() {
    const usedFiberColors = this.getCableSection()
      .getBunchConnectionsInNode(this.getNode())
      .filter((bc) => bc.getBunch().id === this.getBunch().id)
      .reduce((acc, bc) => {
        return acc.concat(Object.values(bc.fibers));
      }, []);

    // return all free fibers of the bunch on this node
    return this.getBunch()
      .getAllFibers()
      .filter((f) => {
        return !usedFiberColors.includes(f.fiber);
      });
  }

  getUnsplicedFibers() {
    return this.getFibers().filter((f) => {
      let spliceConnections = this.getSpliceConnections();
      let splicedFiber = spliceConnections.reduce((acc, sc) => {
        let fiber1 = sc.getFiber1();
        let fiber2 = sc.getFiber2();
        if (
          fiber1.bunch_connection.id === this.id &&
          f.fiber === fiber1.fiber
        ) {
          return fiber2.fiber;
        } else if (
          fiber2.bunch_connection.id === this.id &&
          f.fiber === fiber2.fiber
        ) {
          return fiber1.fiber;
        } else {
          return acc;
        }
      }, null);
      return !splicedFiber;
    });
  }

  getFibers() {
    let code = this.getCableSection().getCable().getColorCode();
    return this.fibers.map((f) => {
      return code.getColorByTitle(f).asFiberOfBunchConnection(this);
    });
  }

  getFiberIds() {
    return this.getFibers().map((fiber) => fiber.id);
  }

  getCableSection() {
    return store.getters["network/cableSectionById"](this.cable_section.id);
  }

  getPipe() {
    return this.getCableSection().getTube().getPipe();
  }

  getConnector() {
    if (this.connector.type == "splicetray") {
      return store.getters["network/spliceTraysById"](this.connector.id);
    } else if (this.connector.type == "patchfield") {
      return store.getters["network/patchFieldsById"](this.connector.id);
    } else {
      console.error("NotImplemented: getConnector for " + this.connector.type);
    }
  }

  getSpliceConnections() {
    if (this.connector.type !== "splicetray") return [];
    let spliceTray = this.getConnector();
    let conn = spliceTray
      .getSpliceConnections()
      .filter(
        (sc) =>
          sc.getBunchConnection1().id === this.id ||
          sc.getBunchConnection2().id === this.id
      );
    // order splice connections by fibers of bunch connection
    let orderedSpliceConnections = this.getFibers().flatMap((fiber) => {
      return conn.filter(
        (spliceConnection) =>
          spliceConnection.getFiber1().id === fiber.id ||
          spliceConnection.getFiber2().id === fiber.id
      );
    });
    return orderedSpliceConnections;
  }

  getBunch() {
    return Bunch.fromBunchConnection(this, this.bunch);
  }

  getNode() {
    return store.getters["network/nodesById"](this.node.id);
  }

  getDirection() {
    let layout = this.getLayoutOnNode(this.getConnector());
    if (layout && "direction" in layout && layout.direction !== null)
      return layout.direction === "left" ? "left" : "right";
    let pipe = this.getPipe();
    if (pipe.loaded) {
      return pipe.getLayoutOnNode(this.getNode()).direction === "left"
        ? "left"
        : "right";
    }
  }
}
export class SpliceTray extends BunchConnector {
  static ENDPOINT = "splicetray";
  static VERBOSE_NAME = "Spleisskasette";
  static VERBOSE_NAME_PLURAL = "Spleisskasetten";

  getSpliceConnections() {
    return this.splice_connections.map((n) =>
      SpliceConnection.from_api(n, this)
    );
  }
}

export class SpliceConnection extends LWLModel {
  static ENDPOINT = "spliceconnection";
  hasFiber(fiber) {
    return this.getBothFibers().some((f) => {
      return (
        fiber.bunch_connection.id == f.bunch_connection.id &&
        fiber.fiber == f.fiber
      );
    });
  }

  getBothFibers() {
    return [this.getFiber1(), this.getFiber2()];
  }
  getFiber1() {
    return Fiber.from_api(this.fiber1, this);
  }

  getBunchConnection1() {
    return store.getters["network/bunchConnectionById"](
      this.bunch_connection_1
    );
  }

  getFiber2() {
    return Fiber.from_api(this.fiber2, this);
  }
  getBunchConnection2() {
    return store.getters["network/bunchConnectionById"](
      this.bunch_connection_2
    );
  }

  getOtherFiber(fiber) {
    return this.getFiber1().id == fiber.id
      ? this.getFiber2()
      : this.getFiber1();
  }

  getSpliceTray() {
    if (!this.splice_tray) return null;
    return store.getters["network/spliceTraysById"]([this.splicetray]);
  }
}

export class Fiber extends LWLModel {
  static VERBOSE_NAME = "Faser";
  static VERBOSE_NAME_PLURAL = "Fasern";

  static async fetchDetails(url) {
    return auth.get(`${config.BACKEND_PORTAL}${url}`).then((response) => {
      return response.data.fibers.map((fiber) => {
        return Fiber.from_api(fiber);
      });
    });
  }

  get id() {
    var slugify = require("slugify");
    // COMMENT: Remove all special characters that are not allowed for css-id
    return (
      this.bunch_connection.id +
      "-" +
      slugify(this.fiber, { replacement: "-", lower: true, strict: true })
    );
  }

  get uid() {
    return `fiber-${this.id || this.pk}`.toLowerCase();
  }

  getTitle() {
    let title = "";
    const bcTitle = this.getBunchConnection().getTitle();
    if (bcTitle) title += bcTitle + " / ";
    title += this.fiber;
    return title;
  }

  getBunchConnection() {
    return store.getters["network/bunchConnectionById"](
      this.bunch_connection.id
    );
  }

  getNetworkRoute() {
    return NetworkRoute.from_api(this.network_route);
  }

  ifFiberEndsOnPort() {
    if (!this.network_route.length) return false;
    return (
      this.network_route[this.network_route.length - 1]?.type === "port" ??
      false
    );
  }

  getEndOfFiberPort() {
    if (!this.ifFiberEndsOnPort()) return null;
    let port_id = this.network_route[this.network_route.length - 1].id;

    if (store.getters["network/patchFieldPortsById"](port_id).id === -1) {
      store.dispatch("network/fetchPatchFieldPortsByIds", {
        projectId: store.getters["projects/project"].id,
        ids: [port_id],
      });
    }

    return store.getters["network/patchFieldPortsById"](port_id);
  }

  getEndOfFiberRouteLabel() {
    if (!this.network_route.length) return "";
    let route = this.network_route;
    if (route[route.length - 1].type === "splicetray") {
      //COMMENT get last node of route
      return `➔ ${route[route.length - 3].title} | SPK ${
        route[route.length - 1]?.title
      }`;
    } else if (route[route.length - 1].type === "port") {
      return `➔ ${route[route.length - 4].title} |
          ${route[route.length - 2].title} |
          ${route[route.length - 1].title}`;
    } else {
      return `➔ ${route[route.length - 1].title}`;
    }
  }

  // COMMENT get tube by node matching on bunch connection
  getTubeByNode(node) {
    let tube = null;
    let bunchConnection = null;
    for (let cableSection of node.getCableSections()) {
      tube = cableSection.getTube();
      let bunchConnections = cableSection.getBunchConnections();
      bunchConnection = bunchConnections.find((el) => {
        if (el.id == this.bunch_connection.id) return el;
      });
      if (bunchConnection) break;
    }
    return tube && bunchConnection ? tube : null;
  }
}

export class SpliceClosure extends LWLModel {
  static ENDPOINT = "spliceclosure";
  static VERBOSE_NAME = "Spleiss-Muffe";
  static VERBOSE_NAME_PLURAL = "Spleiss-Muffen";

  getNode() {
    return store.getters["network/nodesById"](this.node);
  }

  getSpliceTrays() {
    store.dispatch(
      "network/fetchAllSpliceTrays",
      store.getters["projects/project"].id
    );
    return store.getters["network/spliceTraysByIds"](
      this.splice_trays.map((ref) => ref.id)
    );
  }

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.PROJECTS, param);
  }

  static async fetchByIds(projectId, ids) {
    return fetchByIds(this, config.endpoints.PROJECTS, projectId, ids);
  }
}

export class Cabinet extends NetworkComponent {
  static ENDPOINT = "cabinet";
  static VERBOSE_NAME = "Schrank";
  static VERBOSE_NAME_PLURAL = "Schränke";

  getNode() {
    return store.getters["network/nodesById"](this.node);
  }

  getPatchFields() {
    store.dispatch(
      "network/fetchAllPatchFields",
      store.getters["projects/project"].id
    );
    return store.getters["network/patchFieldsByIds"](this.patchfields);
  }

  getBunchConnections() {
    let bcs = [];
    this.getPatchFields().forEach((pf) => {
      bcs = bcs.concat(pf.getBunchConnections());
    });
    return bcs;
  }
}

export class PatchField extends BunchConnector {
  static ENDPOINT = "patchfield";
  static VERBOSE_NAME = "Patchfeld";
  static VERBOSE_NAME_PLURAL = "Patchfelder";

  getNode() {
    return store.getters["network/nodesById"](this.node.id);
  }

  getCabinet() {
    return store.getters["network/cabinetsById"](this.cabinet.id);
  }

  getUsedPorts() {
    store.dispatch(
      "network/fetchAllPatchFieldPorts",
      store.getters["projects/project"].id
    );
    return store.getters["network/patchFieldPortsByIds"](this.ports);
  }

  getUsedPort(number) {
    const port = this.getUsedPorts().find((p) => p.number == number);
    if (port) {
      return PatchFieldPort.from_api(port, this);
    }
  }

  getEmptyPorts() {
    return this.getPorts().filter((p) => p.id === null);
  }

  getEmptyPort(number) {
    return PatchFieldPort.from_api(
      {
        id: null,
        number: number,
        fiber: null,
        bunch_connection: null,
        provider: null,
        service_level: {
          id: 0,
          label: LWLModel.SERVICE_LEVEL_UNDEFINED,
        },
        status: {
          id: 0,
          label: "Nicht verbunden",
        },
      },
      this
    );
  }
  getPortByNumber(number) {
    return this.getUsedPort(number) || this.getEmptyPort(number);
  }
  getPorts() {
    let ports = [];
    for (let i = 0; i < this.type.ports; i++) {
      ports.push(this.getPortByNumber(i + 1));
    }
    return ports;
  }
}

export class PatchfieldPortRoute extends LWLModel {
  static async fetchRoute(route_url, signal) {
    return auth
      .getWithExternalSignal(`${config.BACKEND_PORTAL}${route_url}`, signal)
      .then((response) => {
        return PatchfieldPortRoute.from_api(response.data);
      });
  }

  getNetworkRoute() {
    return NetworkRoute.from_api(this.network_route);
  }
}

export class PatchFieldPort extends NetworkComponent {
  static ENDPOINT = "patchfieldport";
  static VERBOSE_NAME = "Patchfeldport";
  static VERBOSE_NAME_PLURAL = "Patchfeldports";
  static CONNECTOR_TYPE_COLOR_PC = "#03b0f0";
  static CONNECTOR_TYPE_COLOR_APC = "#01b050";
  static CONNECTOR_TYPE_COLOR_DEFAULT = "white";

  getPatchField() {
    return store.getters["network/patchFieldsById"](this.patchfield);
  }

  getCabinet() {
    return this.getPatchField().getCabinet();
  }

  getBunchConnection() {
    return this.getPatchField()
      .getBunchConnections()
      .find((bc) => bc.id == this.bunch_connection);
  }

  getRoute() {
    store.dispatch("network/fetchPortRoute", { port: this });
    return store.getters["network/portRouteById"](this.id);
  }

  getPortConnection() {
    return store.getters["network/patchFieldPortConnectionsById"](
      this.port_connection
    );
  }

  getProvider() {
    return store.getters["providers/providersById"](this.provider);
  }

  getProviderLabel() {
    if (!this.provider) return "Keinem Provider zugeordnet";
    return this.getProvider().title;
  }

  getServiceLevelLabel() {
    if (!this.service_level) return "Keinem Service-Level zugeordnet";
    return this.service_level.label;
  }

  getFullTitleWithRoute() {
    let patchField = this.getPatchField();
    let cabinet = patchField.getCabinet();
    return `Port ${this.number} - ${patchField.title} - ${cabinet.title}`;
  }
}

export class PatchFieldPortConnection extends LWLModel {
  static ENDPOINT = "patchfieldportconnection";
  static VERBOSE_NAME = "Patchfeldport-Verbindung";
  static VERBOSE_NAME_PLURAL = "Patchfeldport-Verbindungen";

  static async fetchAll(param) {
    return fetchAllWithPaging(this, config.endpoints.PROJECTS, param);
  }
  static async fetchByIds(projectId, ids) {
    return fetchByIds(this, config.endpoints.PROJECTS, projectId, ids);
  }

  getTitle() {
    return this.title;
  }

  getPort1() {
    return store.getters["network/patchFieldPortsById"](this.port1.id);
  }

  getPort2() {
    return store.getters["network/patchFieldPortsById"](this.port2.id);
  }

  getOtherPort(portId) {
    let otherPortId = portId === this.port1.id ? this.port2.id : this.port1.id;
    return store.getters["network/patchFieldPortsById"](otherPortId);
  }
}

export class TubeConnector extends LWLModel {
  static ENDPOINT = "tubeconnector";
  getNode() {
    return store.getters["network/nodesById"](this.node.id);
  }
  getTube1() {
    return store.getters["network/tubesById"](this.tube1.id);
  }
  getTube2() {
    return store.getters["network/tubesById"](this.tube2.id);
  }
  getTube1Node() {
    return this.getTube1().getOtherNode(this.getNode());
  }
  getTube2Node() {
    return this.getTube2().getOtherNode(this.getNode());
  }
  getTube1Pipe() {
    return store.getters["network/pipesById"](this.getTube1().pipe);
  }
  getTube2Pipe() {
    return store.getters["network/pipesById"](this.getTube2().pipe);
  }
  getOtherTube(tube) {
    return this.getTube1().id === tube.id ? this.getTube2() : this.getTube1();
  }
}

export const NetworkStoreModule = new SimpleModuleGenerator(
  {
    nodes: NetworkNode,
    pipes: Pipe,
    tubes: Tube,
    cables: Cable,
    spliceTrays: SpliceTray,
    spliceClosures: SpliceClosure,
    spliceConnections: SpliceConnection,
    fibers: Fiber,
    cabinets: Cabinet,
    patchFields: PatchField,
    patchFieldPorts: PatchFieldPort,
    patchFieldPortConnections: PatchFieldPortConnection,
  },
  {
    state: {
      portRoutes: [],
      cableSectionBunchRoute: [],
      fetchingAllNodes: [],
      fetchedAllNodes: [],
    },
    getters: {
      nodesComponents: (state) => {
        return state.nodes.filter(
          (node) =>
            node.type === "well" ||
            node.type === "distributor" ||
            node.type === "technicaltransferpoint" ||
            node.type === "center" ||
            node.type === "vnode" ||
            node.type === "bordernode" ||
            node.type === "note" ||
            node.type === "interestnote"
        );
      },
      nodesMain: (state) => {
        return state.nodes.filter((node) => node.isOfMainType());
      },
      nodesDistributors: (state, getters) => {
        return state.nodes.filter((node) => node.type === "distributor");
      },
      nodesCenters: (state, getters) => {
        return state.nodes.filter((node) => node.type === "center");
      },
      nodesWells: (state, getters) => {
        return state.nodes.filter((node) => node.type === "well");
      },
      nodesVNodes: (state, getters) => {
        return state.nodes.filter((node) => node.type === "vnode");
      },
      nodesBorderNodes: (state, getters) => {
        return state.nodes.filter((node) => node.type === "bordernode");
      },
      nodesTechnicalTransferpoint: (state, getters) => {
        return state.nodes.filter(
          (node) => node.type === "technicaltransferpoint"
        );
      },
      notes: (state, getters) => {
        return state.nodes.filter((node) => node.type === "note");
      },
      interestNotes: (state, getters) => {
        return state.nodes.filter((node) => node.type === "interestnote");
      },
      mapNotes: (state, getters) => {
        return getters.notes.filter(
          (node) =>
            node.display_on_map === Note.DISPLAY_MAP ||
            node.display_on_map === Note.DISPLAY_DEFAULT
        );
      },
      schemaNotes: (state, getters) => {
        return getters.notes.filter(
          (node) =>
            node.display_on_map === Note.DISPLAY_SCHEMA ||
            node.display_on_map === Note.DISPLAY_DEFAULT
        );
      },
      mapInterestNotes: (state, getters) => {
        return getters.interestNotes.filter(
          (node) =>
            node.display_on_map === InterestNote.DISPLAY_MAP ||
            node.display_on_map === InterestNote.DISPLAY_DEFAULT
        );
      },
      schemaInterestNotes: (state, getters) => {
        return getters.interestNotes.filter(
          (node) =>
            node.display_on_map === InterestNote.DISPLAY_SCHEMA ||
            node.display_on_map === InterestNote.DISPLAY_DEFAULT
        );
      },
      nodesHouseboxes: (state, getters) => {
        return state.nodes.filter((node) => node.type === "housebox");
      },
      nodesHouseBoxById: (state, getters) => (id) => {
        return getters.nodesHouseboxes.find((node) => node.id == id) ?? null;
      },
      nodesNoteById: (state, getters) => (id) => {
        return getters.notes.find((node) => node.id == id) ?? null;
      },
      portRouteById: (state, getters) => (id) => {
        return state.portRoutes.find((r) => r.id === id);
      },
      cableSectionBunchRouteById: (state, getters) => (id) => {
        return state.cableSectionBunchRoute.find((r) => r.cable_section === id);
      },
      // TODO: should be refactored like fibersByBunchConnection (Fiber - state)
      nodeFibersById: (state, getters) => (id) => {
        let node = state.nodes.find((r) => r.id === id);
        if (node) return node._fetched.fibers;
      },
      fibersByBunchConnection: (state) => (bunchConnection) => {
        let fiberIds = bunchConnection.getFiberIds();
        return state.fibers.filter((fiber) => fiberIds.includes(fiber.id));
      },
    },
    mutations: {
      addPortRoute(state, route) {
        let index = state.portRoutes.findIndex((r) => r.id === route.id);
        if (index > -1) {
          state.portRoutes[index] = route;
        } else {
          state.portRoutes.push(route);
        }
      },
      addCableSectionBunchRoute(state, route) {
        let index = state.cableSectionBunchRoute.findIndex(
          (r) => r.cable_section === route.cable_section
        );
        if (index > -1) {
          state.cableSectionBunchRoute[index] = route;
        } else {
          state.cableSectionBunchRoute.push(route);
        }
      },
      addNodeFibers(state, fibers) {
        let index = state.nodes.findIndex((n) => n.id === fibers.id);
        if (index > -1) {
          state.nodes[index]._fetched.fibers = fibers;
        } else {
          console.error("cannot add node fibers. node not found.");
        }
      },
      addNodesHouseBox(state, houseBox) {
        let item = houseBox.object || houseBox;
        let index = state.nodes.findIndex(
          (node) => node.type === "housebox" && node.id == item.id
        );
        if (index > -1) {
          Object.assign(state.nodes[index], item);
        } else {
          state.nodes.push(item);
        }
      },
      addSpliceConnections(state, spliceConnection) {
        let st_index = state.spliceTrays.findIndex(
          (tray) => tray.id === spliceConnection.splice_tray
        );
        if (st_index > -1) {
          let sc_index = state.spliceTrays[
            st_index
          ].splice_connections.findIndex((sc) => sc.id === spliceConnection.id);
          if (sc_index > -1) {
            //merge the two items
            Object.assign(
              state.spliceTrays[st_index].splice_connections[sc_index],
              spliceConnection
            );
          }
        } else {
          console.error("cannot add splice connection. tray not found.");
        }
      },
      setTubeConnectors(state, payload) {
        state.nodes.forEach((node, nindex) => {
          if (node.id === payload.node.id) {
            let tindex = node.tube_connectors.findIndex(
              (t) => t.id === payload.id
            );
            if (tindex > -1) {
              state.nodes[nindex].tube_connectors[tindex] = payload;
            } else {
              state.nodes[nindex].tube_connectors.push(payload);
            }
          }
        });
      },
      removeTubeConnector(state, payload) {
        state.nodes.forEach((node) => {
          if (node.id === payload.node.id) {
            node.tube_connectors = node.tube_connectors.filter(
              (t) => t.id !== payload.id
            );
          }
        });
      },
      setFetchedAllNodes(state, fetched) {
        state.fetchedAllNodes.push(fetched);
      },
      setFetchingAllNodes(state, fetched) {
        state.fetchedAllNodes.push(fetched);
      },
      unsetFetchingAllNodes(state, fetched) {
        let index = state.fetchedAllNodes.findIndex((x) => x === fetched);
        if (index > -1) state.fetchedAllNodes.splice(index, 1);
      },
      setResetPortRoutes(state) {
        state.portRoutes = [];
      },
    },
    actions: {
      addNode({ commit, getters }, node) {
        commit("addNodes", { object: node });
      },
      async fetchNodesByAllTypes({ dispatch }, project_id) {
        return dispatch("fetchNodesByTypes", {
          project_id,
          types: NetworkNode.TYPES,
        });
      },
      async fetchNodesByType({ dispatch }, { project_id, type }) {
        let types = Object.entries(NetworkNode.TYPES)
          .filter(([key, value]) => value === type)
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
        return dispatch("fetchNodesByTypes", { project_id, types });
      },
      async fetchNodesByTypes({ commit, getters }, { project_id, types }) {
        let fetched = getters.fetchedAllNodes;
        let fetching = getters.fetchingAllNodes;
        let requesting = Object.keys(types).filter((type) => {
          return !fetched.includes(type) && !fetching.includes(type);
        });

        if (requesting.length === 0) return;

        types = Object.entries(NetworkNode.TYPES)
          .filter(([key, value]) => requesting.includes(key))
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

        requesting.forEach((type) => {
          commit("setFetchingAllNodes", type);
        });

        let toast = useToast();
        let toastId = toast.info(`Lade Netzwerknoten`, { timeout: false });

        for (const [type, Model] of Object.entries(types)) {
          toast.update(toastId, {
            content: `Lade ${Model.VERBOSE_NAME_PLURAL} ...`,
          });

          let { result, next } = await Model.fetchAll(project_id);
          result.forEach((item) => {
            commit("addNodes", item);
          });

          //paging support
          while (next) {
            let response = await Model.fetchAll({ next });
            result = response.result;
            next = response.next;

            result.forEach((item) => {
              commit("addNodes", item);
            });
          }

          commit("setFetchedAllNodes", type);
          commit("unsetFetchingAllNodes", type);
        }
        toast.dismiss(toastId);
      },
      async fetchPortRoute({ commit, getters }, { port, signal }) {
        if (!getters.portRouteById(port.id)) {
          let result = await PatchfieldPortRoute.fetchRoute(
            port.route_url,
            signal
          );
          commit("addPortRoute", result);
        }
      },
      async fetchCableSectionBunchRoute(
        { commit, getters },
        { project, cableSection, node, direction }
      ) {
        let url = `${project.id}/cablesection/${cableSection.id}/bunch-routes/${node.id}/${direction}/`;
        let result = await CableSectionBunchRoute.fetchRoute(url);
        commit("addCableSectionBunchRoute", result);
      },
      async fetchNodeFibers({ commit, getters }, node) {
        if (!getters.nodeFibersById(node.id)) {
          let result = await NetworkNodeFibers.fetchFibers(node.fibers_url);
          commit("addNodeFibers", result);
        } else {
          console.log("dont fetch fibers for node", node);
        }
      },
      async fetchBunchConnectionFibers({ commit, getters }, bunchConnection) {
        if (getters.fibersByBunchConnection(bunchConnection).length === 0) {
          let result = await Fiber.fetchDetails(bunchConnection.fibers_url);
          result.forEach((fiber) => {
            commit("addFibers", fiber);
          });
        } else {
          console.log(
            "dont fetch fibers for bunch connection",
            bunchConnection
          );
        }
      },
      async deleteSpliceTray({ commit, getters }, payload) {
        let result = await SpliceTray.delete(payload);
        commit("removeSpliceTray", payload);
      },
      async deletePatchField({ commit, getters }, payload) {
        let result = await PatchField.delete(payload);
        commit("removePatchField", payload);
      },
      async deleteBunchConnection({ commit, getters }, payload) {
        let result = await BunchConnection.delete(payload);
        commit("removeBunchConnections", payload);
      },
      async deleteTubeConnector({ commit, getters }, payload) {
        let result = await TubeConnector.delete(payload);
        commit("removeTubeConnector", payload);
      },
      async connectNodesWithPipe({ commit, getters }, { builder, data }) {
        let response = await builder.connectNodesWithPipe(data);
        updateStateFromAPI(response);
      },
      async assignHouseBoxToOtherNode({ commit, getters }, { builder, data }) {
        let response = await builder.assignHouseBoxToOtherNode(data);
        updateStateFromAPI(response);
      },
      async splitAndConnectTube({ commit, getters }, { builder, data }) {
        let response = await builder.splitAndConnectTube(data);
        updateStateFromAPI(response);
      },
      async insertPipeAndConnectTube({ commit, getters }, { builder, data }) {
        let response = await builder.insertPipeAndConnectTube(data);
        updateStateFromAPI(response);
      },
      async joinCablesInNode({ commit, getters }, { builder, data }) {
        let response = await builder.joinCablesInNode(data);
        updateStateFromAPI(response);
      },
      async splitCableInNode({ commit, getters }, { builder, data }) {
        let response = await builder.splitCableInNode(data);
        updateStateFromAPI(response);
      },
      async removeTubeFromHousebox({ commit, getters }, { builder, data }) {
        let response = await builder.removeTubeFromHousebox(data);
        updateStateFromAPI(response);
      },
      async insertCableIntoTubes({ commit, getters }, { builder, data }) {
        let response = await builder.insertCableIntoTubes(data);
        updateStateFromAPI(response);
      },
      async insertPipeIntoTubes({ commit, getters }, { builder, data }) {
        let response = await builder.insertPipeIntoTubes(data);
        updateStateFromAPI(response);
      },
      async updatePipes({ commit, getters }, { builder, data }) {
        let response = await builder.updatePipes(data);
        updateStateFromAPI(response);
      },
      async updateCablesInTubes({ commit, getters }, { builder, data }) {
        let response = await builder.insertPipeIntoTubes(data);
        updateStateFromAPI(response);
      },
      async addSpliceTrayToNode({ commit, getters }, { builder, data }) {
        let response = await builder.addSpliceTrayToNode(data);
        updateStateFromAPI(response);
      },
      async moveSpliceTrayInNode({ commit, getters }, { builder, data }) {
        let response = await builder.moveSpliceTrayInNode(data);
        updateStateFromAPI(response);
      },
      async addSpliceClosureToNode({ commit, getters }, { builder, data }) {
        let response = await builder.addSpliceClosureToNode(data);
        updateStateFromAPI(response);
      },
      async insertBunchIntoTray({ commit, getters }, { builder, data }) {
        let response = await builder.insertBunchIntoTray(data);
        updateStateFromAPI(response);
      },
      async insertBunchIntoPatchField({ commit, getters }, { builder, data }) {
        let response = await builder.insertBunchIntoPatchField(data);
        updateStateFromAPI(response);
      },
      async removeBunchFromConnector({ commit, getters }, { builder, data }) {
        let response = await builder.removeBunchFromConnector(data);
        updateStateFromAPI(response);
      },
      async spliceFibersInTray({ commit, getters }, { builder, data }) {
        let response = await builder.spliceFibersInTray(data);
        updateStateFromAPI(response);
      },
      async removeSplicesOfBunchConnectionInTray(
        { commit, getters },
        { builder, data }
      ) {
        let response = await builder.removeSplicesOfBunchConnectionInTray(data);
        updateStateFromAPI(response);
      },
      async addCabinetToNode({ commit, getters }, { builder, data }) {
        let response = await builder.addCabinetToNode(data);
        updateStateFromAPI(response);
      },
      async addPatchFieldToCabinet({ commit, getters }, { builder, data }) {
        let response = await builder.addPatchFieldToCabinet(data);
        updateStateFromAPI(response);
      },
      async connectTubes({ commit, getters }, { builder, data }) {
        let response = await builder.connectTubes(data);
        updateStateFromAPI(response);
      },
      async unlinkTubes({ commit, getters }, { builder, data }) {
        let response = await builder.unlinkTubes(data);
        updateStateFromAPI(response);
      },
      async connectPipes({ commit, getters }, { builder, data }) {
        let response = await builder.connectPipes(data);
        updateStateFromAPI(response);
      },
      async unlinkPipes({ commit, getters }, { builder, data }) {
        let response = await builder.unlinkPipes(data);
        updateStateFromAPI(response);
      },
      async connectPatchFieldPorts({ commit, getters }, { builder, data }) {
        let response = await builder.connectPatchFieldPorts(data);
        updateStateFromAPI(response);
      },
      async unlinkPatchFieldPorts({ commit, getters }, { builder, data }) {
        let response = await builder.unlinkPatchFieldPorts(data);
        updateStateFromAPI(response);
      },
      async removeFiberFromPatchfield({ commit, getters }, { builder, data }) {
        let response = await builder.removeFiberFromPatchfield(data);
        updateStateFromAPI(response);
      },
      async updateVNodePosition({ commit, getters }, { builder, data }) {
        let response = await builder.updateVNodePosition(data);
        updateStateFromAPI(response);
      },
      async removePipeFromNode({ commit, getters }, { builder, data }) {
        let response = await builder.removePipeFromNode(data);
        updateStateFromAPI(response);
      },
      async fetchHouseBoxAndUpdateState({ commit, getters }, houseBox) {
        // COMMENT should be refactored, when there is an exclusive state for HouseBox
        let response = {
          nodeshousebox: [await HouseBox.fetchDetails(houseBox)],
        };
        updateStateFromAPI(response);
      },
      async patchTubeConnector({ commit, getters }, { builder, data }) {
        // COMMENT should be refactored, when there is an exclusive state for TubeConnectors
        let response = await builder.patchTubeConnector(data);
        updateStateFromAPI(response);
      },
      async deleteNote({ commit, getters }, payload) {
        commit("removeNode", payload);
      },
      async resetPortRoutes({ commit, getters }) {
        commit("setResetPortRoutes");
      },
      async removeSpliceConnectionInTray(
        { commit, getters },
        { builder, data }
      ) {
        let response = await builder.removeSpliceConnectionInTray(data);
        updateStateFromAPI(response);
      },
      async updateSpliceConnectionsOfBunchConnection(
        { commit, getters },
        { builder, data }
      ) {
        let response = await builder.updateSpliceConnectionsOfBunchConnection(
          data
        );
        updateStateFromAPI(response);
      },
      async removeFibersOfBunchConnectionInTray(
        { commit, getters },
        { builder, data }
      ) {
        let response = await builder.removeFibersOfBunchConnectionInTray(data);
        updateStateFromAPI(response);
      },
      async moveCableInOtherTube({ commit, getters }, { builder, data }) {
        let response = await builder.moveCableInOtherTube(data);
        updateStateFromAPI(response);
      },
      async createNetworkNode({ commit, getters }, { builder, data }) {
        let response = await builder.createNetworkNode(data);
        updateStateFromAPI(response);
      },
      async editNetworkNodeType({ commit, getters }, { builder, data }) {
        let response = await builder.editNetworkNodeType(data);
        updateStateFromAPI(response);
      },
    },
  },
  {
    cables: {
      init: (generator) => {
        generator.addGetter("cableSectionById", (state) => (id) => {
          return state.cableSectionsIndex[id];
        });

        generator.addGetter("cableSectionByIds", (state, getters) => (ids) => {
          return ids
            .map((id) => getters.cableSectionById(id))
            .filter((section) => section !== undefined);
        });

        generator.addGetter("bunchConnectionById", (state) => (id) => {
          return state.bunchConnectionsIndex[id];
        });

        generator.addGetter(
          "bunchConnectionByIds",
          (state, getters) => (ids) => {
            return ids
              .map((id) => getters.bunchConnectionById(id))
              .filter((section) => section !== undefined);
          }
        );

        generator.addAction(
          "updateBunchConnection",
          async ({ commit, getters }, { builder, data }) => {
            let response = await builder.updateBunchConnection(data);
            updateStateFromAPI(response);
          }
        );

        generator.addState("cableSectionsIndex", {});
        generator.addState("bunchConnectionsIndex", {});
      },
      preAdd: (state, payload) => {
        const oldCableId = state.cablesIndex.get(payload.id);
        let newCable = Cable.fromAPI(payload);

        if (oldCableId) {
          let oldCable = state.cables[oldCableId];
          let oldBunchConnections = oldCable.sections.flatMap(
            (section) => section.bunch_connections
          );
          let newBunchConnections = newCable.sections.flatMap(
            (section) => section.bunch_connections
          );
          // find bunch connections that are removed
          let removedBunchConnections = oldBunchConnections.reduce(
            (acc, bc) => {
              if (!newBunchConnections.some((nbc) => nbc.id === bc.id)) {
                acc.push(bc);
              }
              return acc;
            },
            []
          );
          // remove bunch connections after update of cable
          removedBunchConnections.forEach((bc) => {
            delete state.bunchConnectionsIndex[bc.id];
          });
        }
      },
      postAdd: (state, payload) => {
        for (let section of payload.sections) {
          if (state.cableSectionsIndex[section.id] === undefined) {
            state.cableSectionsIndex[section.id] =
              CableSection.fromAPI(section);
          } else {
            //merge the two items - preserve reactivity
            Object.assign(state.cableSectionsIndex[section.id], section);
          }

          for (let bc of section.bunch_connections) {
            if (state.bunchConnectionsIndex[bc.id] === undefined) {
              state.bunchConnectionsIndex[bc.id] = BunchConnection.fromAPI(bc);
            } else {
              //merge the two items - preserve reactivity
              Object.assign(state.bunchConnectionsIndex[bc.id], bc);
            }
          }
        }
      },
      preRemove: (state, payload) => {
        const index = state.cablesIndex.get(payload.id);
        const cable = state.cables[index];
        const sections = cable.getSections();

        // remove sections and bundle connections if the references are the
        //same as the cable that will be deleted after preRemove
        for (let section of sections) {
          if (section.cable.id === payload.id) {
            delete state.cableSectionsIndex[section.id];
            for (let bc of section.bunch_connections) {
              delete state.bunchConnectionsIndex[bc.id];
            }
          }
        }
      },
    },
  }
).module();

export const NetworkModalStoreModule = {
  namespaced: true,
  state: {
    node: null,
    currentFiber: null,
    patchFieldPort: null,
    nodeModalStatus: false,
    houseBoxUsageUnitModalStatus: false,
    patchFieldPortModalStatus: false,
    nodeAction: null,
    houseBoxUsageUnitModalModalAction: null,
    patchFieldPortAction: null,
  },
  getters: {
    node: (state) => state.node,
    currentFiber: (state) => state.currentFiber,
    patchFieldPort: (state) => state.patchFieldPort,
    nodeModalStatus: (state) => state.nodeModalStatus,
    houseBoxUsageUnitModalStatus: (state) => state.houseBoxUsageUnitModalStatus,
    patchFieldPortModalStatus: (state) => state.patchFieldPortModalStatus,
    nodeAction: (state) => state.nodeAction,
    houseBoxUsageUnitModalModalAction: (state) =>
      state.houseBoxUsageUnitModalModalAction,
    patchFieldPortAction: (state) => state.patchFieldPortAction,
  },
  mutations: {
    setNode(state, payload) {
      state.node = payload;
    },
    setCurrentFiber(state, payload) {
      state.currentFiber = payload;
    },
    setPatchFieldPort(state, payload) {
      state.patchFieldPort = payload;
    },
    setNodeModalStatus(state, payload) {
      state.nodeModalStatus = payload;
    },
    setHouseBoxUsageUnitModalModalStatus(state, payload) {
      state.houseBoxUsageUnitModalStatus = payload;
    },
    setPatchFieldPortModalStatus(state, payload) {
      state.patchFieldPortModalStatus = payload;
    },
    setNodeAction(state, payload) {
      state.nodeAction = payload;
    },
    setHouseBoxUsageUnitModalModalAction(state, payload) {
      state.houseBoxUsageUnitModalModalAction = payload;
    },
    setPatchFieldPortAction(state, payload) {
      state.patchFieldPortAction = payload;
    },
  },
  actions: {
    openNodeModal(context, payload) {
      context.commit("setNode", payload.node);
      context.commit("setNodeAction", payload.action);
      context.commit("setNodeModalStatus", true);
    },
    closeNodeModal(context) {
      context.commit("setNode", null);
      context.commit("setNodeAction", { icon: "", name: "" });
      context.commit("setNodeModalStatus", false);
    },
    openHouseBoxUsageUnitModal(context, payload) {
      context.commit("setNode", payload.node);
      context.commit("setCurrentFiber", payload.currentFiber);
      context.commit("setHouseBoxUsageUnitModalModalAction", payload.action);
      context.commit("setHouseBoxUsageUnitModalModalStatus", true);
    },
    closeHouseBoxUsageUnitModal(context, payload) {
      context.commit("setNode", null);
      context.commit("setCurrentFiber", null);
      context.commit("setHouseBoxUsageUnitModalModalAction", {
        icon: "",
        name: "",
      });
      context.commit("setHouseBoxUsageUnitModalModalStatus", false);
    },
    openPatchFieldPortModal(context, payload) {
      context.commit("setPatchFieldPort", payload.port);
      context.commit("setPatchFieldPortAction", payload.action);
      context.commit("setPatchFieldPortModalStatus", true);
    },
    closePatchFieldPortModal(context) {
      context.commit("setPatchFieldPort", null);
      context.commit("setPatchFieldPortAction", { icon: "", name: "" });
      context.commit("setPatchFieldPortModalStatus", false);
    },
  },
};
