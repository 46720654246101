// import Vue from 'vue'
import axios from "axios";
import { setConcurrentRequestsInterceptors } from "@/utils/axios.interceptors";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import config from "@/app.config";

// use this instance for get requests (with interseptors)
// Create `axios-cache-adapter` instance
// import { setupCache } from 'axios-cache-adapter'
// use this instance for post, patch, put and delete requests (no interseptors for this instance)
const write_api = axios.create({});
const read_api = axios.create({});

export const abortController = new AbortController();
const signal = abortController.signal;

setConcurrentRequestsInterceptors(read_api);

const COOKIE_NAME = "lwl-jwt";
const COOKIE_BASE_NAME = "lwl";
const TOKEN_KEY = `${COOKIE_BASE_NAME}-token`;
const REFRESH_TOKEN_KEY = `${COOKIE_BASE_NAME}-refresh-token`;
const USERNAME_KEY = "username";

class AuthService {
  async login(user) {
    return axios
      .post(config.endpoints.AUTH + "obtain/", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        const accessToken = response.data.access;
        if (accessToken) {
          this.setLocalStorageAndCookie(
            accessToken,
            response.data.refresh,
            user
          );
          return accessToken;
        }
      });
  }

  refresh() {
    return write_api
      .post(config.endpoints.AUTH + "refresh/", {
        refresh: this.getRefreshToken(),
      })
      .then((response) => {
        const accessToken = response.data.access;
        if (accessToken) {
          this.setLocalStorageAndCookie(accessToken, this.getRefreshToken());
          return accessToken;
        }
      });
  }

  logout() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USERNAME_KEY);
    Cookies.remove(COOKIE_NAME);
  }

  setLocalStorageAndCookie(accessToken, refreshToken, user = null) {
    localStorage.setItem(TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    Cookies.set(COOKIE_NAME, accessToken, { expires: 31 });
    if (user) localStorage.setItem(USERNAME_KEY, user.username);
  }

  whoami() {
    return this.get(config.endpoints.WHOAMI);
  }

  getAuthHeader() {
    const token = this.getToken();
    if (token) {
      return {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
    } else {
      return {};
    }
  }

  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  isLoggedIn() {
    let token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      let decoded = jwt_decode(token);
      return new Date(decoded.exp * 1000) > new Date();
    }
    return false;
  }

  isDueForRefresh() {
    let token = this.getToken();
    let decoded = jwt_decode(token);
    const expiresInMs = new Date(decoded.exp * 1000) - new Date();
    const nextTenMinutes = 10 * 60 * 1000;
    return expiresInMs < nextTenMinutes;
  }

  getWithExternalSignal(url, signal) {
    let headers = this.getAuthHeader();
    const makeRequest = () => read_api.get(url, { headers, signal });

    if (this.isDueForRefresh()) {
      return this.refresh().then(makeRequest);
    }
    return read_api.get(url, { headers, signal });
  }

  sendRequest(request) {
    if (this.isDueForRefresh()) {
      return this.refresh().then(request);
    }
    return request();
  }

  get(url) {
    let headers = this.getAuthHeader();
    const makeRequest = () => read_api.get(url, { headers, signal });
    return this.sendRequest(makeRequest);
  }

  put(url, data) {
    let headers = this.getAuthHeader();
    headers["Content-Type"] = "multipart/form-data";
    const makeRequest = () => write_api.put(url, data, { headers, signal });
    return this.sendRequest(makeRequest);
  }

  post(url, data) {
    let headers = this.getAuthHeader();
    const makeRequest = () => write_api.post(url, data, { headers, signal });
    return this.sendRequest(makeRequest);
  }

  patch(url, data) {
    let headers = this.getAuthHeader();
    const makeRequest = () => write_api.patch(url, data, { headers, signal });
    return this.sendRequest(makeRequest);
  }

  delete(url) {
    const headers = this.getAuthHeader();
    const makeRequest = () => write_api.delete(url, { headers, signal });
    return this.sendRequest(makeRequest);
  }
}

export default new AuthService();
